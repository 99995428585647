<template>
  <v-container fluid grid-list-md>
    <AppTitleBar>
      <template v-slot:title>{{ $t('labels.transactions') }}</template>
    </AppTitleBar>

    <SearchBar :context="context" :search-fields="searchFields" :filterItems="filterItems" />
    <v-card flat class="main-card">
      <v-card-text class="py-1 px-0">
        <v-data-table
          :headers="headers"
          :items="transactions"
          :loading="loading"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          must-sort
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
          :server-items-length="tableItemsCount"
          class="main-table elevation-0"
        >
          <template v-slot:[`item.category`]="{ item }">
            <v-tooltip top transition="fade-transition" v-if="item.category === 'outbound'">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on">{{ $vuetify.icons.values.ingress }}</v-icon>
              </template>
              <span>{{ $t('labels.outbound') }}</span>
            </v-tooltip>
            <v-tooltip top transition="fade-transition" v-else>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on">{{ $vuetify.icons.values.egress }}</v-icon>
              </template>
              <span>{{ $t('labels.inbound') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.success`]="{ item }">
            <v-tooltip top transition="fade-transition" v-if="item.success">
              <template v-slot:activator="{ on }">
                <v-icon small class="success--text" v-on="on">{{ $vuetify.icons.values.check }}</v-icon>
              </template>
              <span>{{ $t('labels.success') }}</span>
            </v-tooltip>
            <v-tooltip top transition="fade-transition" v-else>
              <template v-slot:activator="{ on }">
                <v-icon small class="error--text" v-on="on">{{ $vuetify.icons.values.warning }}</v-icon>
              </template>
              <span>{{ $t('labels.error') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.insurer`]="{ item }">
            {{ $t(`labels.${item.insurer.toUpperCase()}`) }}
          </template>
          <template v-slot:[`item.created_on`]="{ item }">
            <span class="text-caption grey--text">{{ transformDate(item.created_on) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top transition="fade-transition">
              <template v-slot:activator="{ on }">
                <v-btn icon small :href="item.file.uris.download" :download="item.file.filename" v-on="on">
                  <v-icon small>{{ $vuetify.icons.values.download }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('labels.download') }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { AppTitleBar } from '@argon/app/components'
import { SearchBar } from '@argon/evo/entities/components'
import { formatDT } from '@argon/app/helpers'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { NAMESPACE } from '../store'
import { Datatable } from '@argon/app/mixins'
import transactionPerimeter from '../perimeters.js'

export default {
  components: {
    AppTitleBar,
    SearchBar
  },
  perimeters: [transactionPerimeter],
  mixins: [Datatable],
  data() {
    return {
      newDialog: false,
      sortBy: 'created_on',
      sortDesc: true,
      headers: [
        { text: '', value: 'category', sortable: false },
        { text: this.$t('labels.file'), value: 'filename', sortable: false },
        { text: this.$t('labels.insurer'), value: 'insurer', sortable: false },
        { text: '', value: 'success', sortable: false },
        { text: '', value: 'created_on', sortable: false },
        { text: '', sortable: false, value: 'actions', align: 'right' }
      ],
      searchFields: [
        { label: 'insurer', type: 'text' },
        { label: 'category', type: 'text' }
      ],
      loading: true,
      context: NAMESPACE
    }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone', 'locale']),
    ...mapState(NAMESPACE, ['transactions', 'tableItemsCount', 'filterObject']),
    filterItems() {
      return this.filterObject ? this.filterObject.items : []
    },
    filterStamp() {
      return this.filterObject ? this.filterObject.stamp : null
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['listTransactions', 'clearTransactions', 'setFilters']),
    setFilterObject(v) {
      this.setFilters(v)
    },
    transformDate(value) {
      return value ? formatDT(value, { format: 'LL LTS', tz: this.timezone, locale: this.locale }) : ''
    },
    fetchData() {
      return new Promise((resolve, reject) => {
        this.listTransactions({ pager: this.pager })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.loading = false
          })
      })
    }
  },
  destroyed() {
    this.clearTransactions().catch(() => {})
  },
  created() {
    this.fetchData()
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData()
    next()
  }
}
</script>
