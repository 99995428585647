var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('AppTitleBar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.transactions')))]},proxy:true}])}),_c('SearchBar',{attrs:{"context":_vm.context,"search-fields":_vm.searchFields,"filterItems":_vm.filterItems}}),_c('v-card',{staticClass:"main-card",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"py-1 px-0"},[_c('v-data-table',{staticClass:"main-table elevation-0",attrs:{"headers":_vm.headers,"items":_vm.transactions,"loading":_vm.loading,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageArray },"server-items-length":_vm.tableItemsCount},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category === 'outbound')?_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.ingress))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.outbound')))])]):_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.egress))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.inbound')))])])]}},{key:"item.success",fn:function(ref){
var item = ref.item;
return [(item.success)?_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"success--text",attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.check))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.success')))])]):_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"error--text",attrs:{"small":""}},on),[_vm._v(_vm._s(_vm.$vuetify.icons.values.warning))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.error')))])])]}},{key:"item.insurer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("labels." + (item.insurer.toUpperCase()))))+" ")]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-caption grey--text"},[_vm._v(_vm._s(_vm.transformDate(item.created_on)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","href":item.file.uris.download,"download":item.file.filename}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.download))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.download')))])])]}}],null,true)})],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }