import { BasePerimeter } from '@argon/iam/perimeters'
import { ROLES } from '@argon/iam/utils'

export default new BasePerimeter({
  purpose: 'transaction',
  can: {
    atLeastAdvisor() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MEMBER)
    },
    atLeastManager() {
      let org = this.child.getters['organizations/activeOrg']
      return this.isLevelOrAbove(org, ROLES.MANAGER)
    }
  }
})
